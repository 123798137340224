<template>
  <b-card>
    <concession-form v-if="concession" :initial-concession="concession"/>
  </b-card>

</template>

<script>
import SiteForm from "@/components/SiteForm";
import ConcessionForm from "@/components/ConcessionForm";

export default {
  name: "ConcessionEdit",
  components: {ConcessionForm, SiteForm},
  data() {
    return {
      concession: false
    }
  },
  mounted() {
    let _this = this;
    this.$store.dispatch('concessionModule/fetch', {
      concession_id: this.$route.params.id
    }).then(function (response) {
      _this.concession = response.data;
    })
  }
}
</script>

<style scoped>

</style>