<template>

  <b-form @submit.prevent>
    <b-row>
      <b-col md="4">
        <b-form-group
            label="Nom"
            label-for="libelle"
            
        >
          <b-form-input
              :class="{'is-invalid' : form_errors.libelle}"
              v-model="site.libelle"
              id="libelle"
              placeholder="Nom du site"
          />
          <small v-if="form_errors.libelle" class="text-danger">{{ form_errors.libelle[0] }}</small>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
            label="Concession"
            label-for="concession"
            :class="{'is-invalid' : form_errors.concession}"
        >
          <v-select id="concession" v-model="site.concession"
                    :options="$store.state.initialConfigModule.initialState.concessions"
                    
                    label="libelle"/>
          <small v-if="form_errors.concession" class="text-danger">{{ form_errors.concession[0] }}</small>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
            label="Ville"
            label-for="ville"
            :class="{'is-invalid' : form_errors.ville}"
        >
          <b-form-input
              :class="{'is-invalid' : form_errors.ville}"
              v-model="site.ville"
              id="libelle"
              placeholder="Ville"
          />
          <small v-if="form_errors.ville" class="text-danger">{{ form_errors.ville[0] }}</small>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
            label="Adresse"
            label-for="adresse"
            :class="{'is-invalid' : form_errors.adresse}"
        >
           <b-form-textarea v-model="site.adresse"></b-form-textarea>
          <small v-if="form_errors.adresse" class="text-danger">{{ form_errors.adresse[0] }}</small>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-button
       
            type="submit"
            variant="primary"
            class="mr-1"
            @click="submit"
        >
          Enregistrer
        </b-button>

      </b-col>
    </b-row>

  </b-form>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'


export default {
  name: 'SiteForm',
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    initialSite: {
      default: false,
    }
  },
  methods: {
    submit() {
      let _this = this;
      _this.form_errors = {};
      this.$store.dispatch('siteModule/siteStore', this.site).then(function (response) {
        _this.$emit('submited', response);
        _this.$router.push('/sites')
      }).catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          _this.form_errors = error.response.data.errors;
        }

      })
    }
  },
  created() {
    this.site = {...this.initialSite};
  },
  data() {
    return {
      site: {
        libelle: '',
        concession: '',

      },
      form_errors: {}
    }
  },
}
/*
<ul class="chosen-results"><li class="active-result result-selected" data-option-array-index="1" style="">MR</li><li class="active-result" data-option-array-index="2" style="">COMPANY</li><li class="active-result" data-option-array-index="3" style="">GARAGE</li><li class="active-result" data-option-array-index="4" style="">MESSRS</li><li class="active-result" data-option-array-index="5" style="">MME</li><li class="active-result" data-option-array-index="6" style="">MMES</li><li class="active-result" data-option-array-index="7" style="">MS</li></ul>
 */
</script>
