<template>

  <b-form @submit.prevent>
    <b-row>
      <b-col md="4">
        <b-form-group
            label="Nom"
            label-for="libelle"
            
        >
          <b-form-input
              :class="{'is-invalid' : form_errors.libelle}"
              v-model="concession.libelle"
              id="libelle"
              placeholder="Nom du concession"
          />
          <small v-if="form_errors.libelle" class="text-danger">{{ form_errors.libelle[0] }}</small>
        </b-form-group>
      </b-col>

      <b-col md="12">
        <b-form-group
            label="Adresse"
            label-for="Adresse"

        >
          <b-form-textarea
              v-model="concession.adresse"
              id="libelle"
              placeholder="Adresse du concession"
          />
          <small v-if="form_errors.adresse" class="text-danger">{{ form_errors.adresse[0] }}</small>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
            label="Code postal"
       

        >
          <b-form-input
              :class="{'is-invalid' : form_errors.codepostal}"
              v-model="concession.codepostal"
              id="codepostal"
              placeholder="Code postal"
          />
          <small v-if="form_errors.codepostal" class="text-danger">{{ form_errors.codepostal[0] }}</small>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
            label="Siret"


        >
          <b-form-input
              :class="{'is-invalid' : form_errors.siret}"
              v-model="concession.siret"
              id="codepostal"
              placeholder="Code postal"
          />
          <small v-if="form_errors.siret" class="text-danger">{{ form_errors.siret[0] }}</small>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
            label="Téléphone"


        >
          <b-form-input
              :class="{'is-invalid' : form_errors.telephone}"
              v-model="concession.telephone"
              id="codepostal"
              placeholder="Code postal"
          />
          <small v-if="form_errors.telephone" class="text-danger">{{ form_errors.telephone[0] }}</small>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-button
            type="submit"
            variant="primary"
            class="mr-1"
            @click="submit"
        >
          Enregistrer
        </b-button>

      </b-col>
    </b-row>

  </b-form>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'


export default {
  name: 'ConcessionForm',
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    initialConcession: {
      default: false,
    }
  },
  methods: {
    submit() {
      let _this = this;
      _this.form_errors = {};
      this.$store.dispatch('concessionModule/store', this.concession).then(function (response) {
        _this.$emit('submited', response);
        _this.$router.push('/concessions');
        _this.$store.dispatch('initialConfigModule/fetchInitialConfig');
      }).catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          _this.form_errors = error.response.data.errors;
        }

      })
    }
  },
  created() {
    this.concession = {...this.initialConcession};
  },
  data() {
    return {
      concession: {
        libelle: '',
      },
      form_errors: {}
    }
  },
}
/*
<ul class="chosen-results"><li class="active-result result-selected" data-option-array-index="1" style="">MR</li><li class="active-result" data-option-array-index="2" style="">COMPANY</li><li class="active-result" data-option-array-index="3" style="">GARAGE</li><li class="active-result" data-option-array-index="4" style="">MESSRS</li><li class="active-result" data-option-array-index="5" style="">MME</li><li class="active-result" data-option-array-index="6" style="">MMES</li><li class="active-result" data-option-array-index="7" style="">MS</li></ul>
 */
</script>
